import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home.page';
import NavBar from './components/navbar-component/navbar.component';

function App() {
  const [isFunctionTriggered, setFunctionTriggered] = useState(false);
  const triggerFunction = () => {
    setFunctionTriggered(!isFunctionTriggered);
  };
  return (
    <div>
     
    <Router basename='/'>
      <NavBar triggerFunction={triggerFunction}/>
      <div className='fragment-body'>
        <Routes>
          <Route path="/" element={<Home isFunctionTriggered={isFunctionTriggered} />} />
        </Routes>
      </div>
    </Router>
   
  </div>
  );
}

export default App;

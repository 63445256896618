import React from 'react';
import './input.css';

const InputComponent = ({placeholder, label, type, nameInput, required, onChange, value}) => {
  

  return (
    <div className='form-workme-input'>
        <div className='workonme-input-label'>{label}</div>
        {
            type === "textArea" ?
            <textarea value={value} onChange={onChange} placeholder={placeholder} className='workonme-input-textarea' name={nameInput} required={required}/>
            :
            <input value={value} onChange={onChange} placeholder={placeholder} className='workonme-input-input' name={nameInput} required={required}/>
        }
    </div>
  );
};

export default InputComponent;
import React from 'react';
import './navbar.css';
import ww_navigation  from '../../assets/ww-logo-d.svg'
import ic_box  from '../../assets/icon-box.svg'
import ic_person  from '../../assets/icon-person.svg'
import ic_phone  from '../../assets/icon-phone.svg'
import { useNavigate } from 'react-router-dom';

const NavBar = ({ triggerFunction }) => {

  const navigate = useNavigate();
  const phoneNumber = "+526241656427"; 
  const phoneNumberCall = "+526241656427";    
  const redirect = () => {
   navigate("/");
  };
  const handleWhatsapp = (message) => {
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };
  const handleCall = () => {
    window.location.href = `tel:${phoneNumberCall}`;
  };
  
  return (
    <div className='container-navbar'>
        <div className='body-navbar navbar-primary'> 
            <div  className='box-item box-show'>
                <button className='button-img-logo' onClick={redirect}>
                <img src={ww_navigation} alt='logo_sublistudio' className='img_logo_nav'></img>
                </button>
            </div>
            <div className='box-item' id='div-space'/>
            <div  className='box-item-rigth'/>
        </div>
        <div className='body-navbar navbar-secondary'> 
            <div className='box-item'/>
            <div  className='box-item-center'>
                <button className='button-style' onClick={() => handleWhatsapp("Hola, me gustaría hacer un pedido.")}>
                    <img src={ic_box} alt='logo_sublistudio' className='img_logo'></img>
                    <div className='title-button-style'>Ordenar aquí</div>
                </button>
                <button className='button-style' onClick={triggerFunction}>
                    <img src={ic_person} alt='logo_sublistudio' className='img_logo'></img>
                    <div className='title-button-style'>Trabaja con nosotros</div>
                </button>
            </div>
            <div  className='box-item-rigth'>
                <button className='button-style button-call' onClick={handleCall}>
                    <img src={ic_phone} alt='logo_sublistudio' className='img_logo'></img>
                    <div className='text-content-call-button'>
                        <div className='title-button-style'>Llámanos</div>
                        <div className='title-button-style'>(624) 1656427</div>
                    </div>
                </button>
            </div>
        </div>
    </div>
  );
};

export default NavBar;

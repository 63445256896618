import React, {useEffect} from 'react';
import "./slider.css"
import { ImageIcon } from '@radix-ui/react-icons';

const SliderClients = ({ data }) => {
  useEffect(() => {
    
    return () => {
    };
  }, []);

  return (
    <div className="slider-container">
        <ul className="horizontal-list">
            {
                data.map((item, index) => (
                    <div key={index} className='slider-container-item'>
                    {
                        item === "" ?
                        <ImageIcon className='slider-container-item-default' />
                        :
                        <img src={require('../../assets/clientes/' + item)} alt='' />
                    }
                        
                    </div>
                ))
            }
        </ul>
    </div>
  );
};

export default SliderClients;

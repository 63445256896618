import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


const center = {
  lat: 23.100657,
  lng: -109.714104
};

const pointsOfInterest = [
  { id: 1, name: "Grupo Water World", position: { lat: 23.1428449802963, lng:-109.70973443816702 }},
  { id: 2, name: "Water World", position: { lat:23.122593864124088, lng:-109.71484714663019} },
  { id: 3, name: "Water World Zacatal", position: { lat:23.096910241345483, lng:-109.71182186204152} },
  { id: 4, name: "Water world", position: { lat:23.080768785485947, lng:-109.72410988805882} },
  { id: 5, name: "Water World PLM", position: { lat:23.07724056275399, lng: -109.70353070483308} },
  { id: 6, name: "Water world", position: { lat:23.070342838892486, lng:-109.7059255373637} },
  { id: 7, name: "Water world", position: { lat:3.05741425037268, lng:-109.69331554889864} },
  { id: 8, name:"Walmart Water world" , position:{ lat: 23.0546076, lng:-109.705393} }
  
];

const MapComponent = () => {
    const containerStyle = {
        width: '100%',
        height: '100%',
      };
    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_ID}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
          >
            {pointsOfInterest.map(point => (
              <Marker key={point.id} position={point.position} label={point.name} />
            ))}
          </GoogleMap>
        </LoadScript>
      );
};

export default MapComponent;

import React, {useRef, useState, useEffect} from 'react';
import './home.css'
import bgHead from '../../assets/img_head.png'
import bgFooter from '../../assets/bg_footer.png'
import imgGarrafas from '../../assets/img_garrafas.png'
import icDot from '../../assets/icon-dot.svg'
import wcmImage from '../../assets/wcm-image.png'
import collectionGallons from '../../assets/img_bg_garrafas.png'
import collageprim from '../../assets/collage-first.png'
import collageDos from '../../assets/collage-second.png'
import collageTres from '../../assets/collage-thirt.png'
import collageCuatro from '../../assets/collage-fouth.png'
import imgGift from '../../assets/img_gift.png'
import logoWhite from '../../assets/logo-ww-white.svg'
import imgMarcaDeAgua from '../../assets/marca-agua-ww.svg'
import iconFacebook from '../../assets/icon-facebook.svg'

import { ArrowUpIcon, CheckboxIcon, SquareIcon } from '@radix-ui/react-icons';
import SliderClients from '../../components/slider-component/slider.component';
import InputComponent from '../../components/input-component/input.component';
import MapComponent from '../../components/map-component/map.component';
import emailjs from 'emailjs-com';
import Lottie from 'react-lottie';
import animationData from '../../assets/animations/animation.json';

const Home = ({ isFunctionTriggered }) => {
    const form = useRef();
    const divRef = useRef(null);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
    const [isCheck, setIsCheck] = useState(false)
    const [isSendButtonDisbaled, setIsSendButtonDisbaled] = useState(true);

    const [nameField, setNameField] = useState("")
    const [emailField, setEmailField] = useState("")
    const [phoneField, setPhoneField] = useState("")
    const [messageField, setMessageField] = useState("")
    
    useEffect(() => {
        if (isFunctionTriggered) {
            gotoForm();
        }
    }, [isFunctionTriggered]);

    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    }
    const gotoForm = () => {
        divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const handleWhatsapp = (message) => {
        const whatsappURL = process.env.REACT_APP_CONTACT_WHA + encodeURIComponent(message);
        console.log(whatsappURL)
        window.open(whatsappURL, '_blank');
      };
      const handleFacebook = () => {
        const whatsappURL = process.env.REACT_APP_CONTACT_FAC;
        window.open(whatsappURL, '_blank');
      };
    const sendEmail = (e) => {
        e.preventDefault();
        const service_id = process.env.REACT_APP_SERVICE_ID
        const template_id = process.env.REACT_APP_TEMPLATE_ID
        const user_id = process.env.REACT_APP_USER_ID

        emailjs
          .sendForm(
            service_id,
            template_id,
            form.current,
            user_id
          )
          .then(
            (result) => {
              console.log('Correo enviado:', result.text);
              setNameField("");
              setEmailField("");
              setPhoneField("");
              setMessageField("");
              setIsCheck(false)
              setIsSendButtonDisbaled(true)
              alert('¡Correo enviado con éxito!');
            },
            (error) => {
              console.error('Error al enviar el correo:', error.text);
              alert('Hubo un problema al enviar el correo. Por favor, intenta de nuevo.');
            }
          );
      };

    const setNameValue = (value) => {
        setNameField(value)
        setIsSendButtonDisbaled(!(value !== "" && emailField !== "" && phoneField !== "" && messageField !== "" && isCheck));
    }

    const setEmailValue = (value) => {
        setEmailField(value)
        setIsSendButtonDisbaled(!(value !== "" && nameField !== "" && phoneField !== "" && messageField !== "" && isCheck));
    }

    const setPhoneValue = (value) => {
        setPhoneField(value)
        setIsSendButtonDisbaled(!(value !== "" && emailField !== "" && nameField !== "" && messageField !== "" && isCheck));
    }

    const setMessageValue = (value) => {
        setMessageField(value)
        setIsSendButtonDisbaled(!(value !== "" && emailField !== "" && phoneField !== "" && nameField !== "" && isCheck));
    }

  return (
    <div className='contiener-home'>
        <div className='dashboard-header-conteiner'>
            <img src={bgFooter} className='dashboard-header-conteiner-img' alt=''/> 
            <div className='dashboard-header-conteiner-content'>
                <div className='dashboard-header-conteiner-content-right'>
                    <img src={bgHead} alt='' className='conteiner-content-img'/>
                </div>
                <div className='dashboard-header-conteiner-content-left'>
                    <div className='content-text-header'>
                        <div className='content-text-header-title'>
                            En Water World
                            El agua de la vida
                        </div>
                        <div className='content-text-header-description'>
                            Compra y descubre la pureza y la frescura del agua purificada
                        </div>

                        <div className='content-text-header-button'>
                            <button className='header-button' onClick={() => handleWhatsapp("Hola, estoy interesado en más información.")}>
                                Conoce más
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div className='conteiner-promosdesc'>
            <div className='conteiner-promosdesc-item'>
                <div className='conteiner-desc'>
                    <div className='conteiner-desc-left'>
                        <div className='content-desc-text'>
                            <div className='content-desc-title'>
                                Acércate y obtén premios por tus compras
                            </div>
                        </div>
                    </div>
                    <div className='conteiner-desc-right img-gift'>
                        <img src={imgGift} alt=''></img>
                    </div>
                </div>
            </div>
            <div className='conteiner-promosdesc-item'>
                <div className='conteiner-liner-item'>
                    <div className='conteiner-liner-title'>Tenemos promociones especiales para ti</div>
                    <div className='conteiner-liner-description'>Visita nuestras plantas</div>
                </div>
            </div>
            <div className='conteiner-promosdesc-item'>
                <div className='conteiner-desc'>
                    <div className='conteiner-desc-left'>
                        <div className='content-desc-text'>
                            <div className='content-desc-title'>
                                Gana 1 llenado Grátis por cada recarga
                            </div>
                        </div>
                    </div>
                    <div className='conteiner-desc-right ligth-conteiner'>
                        <img src={imgGarrafas} alt=''></img>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-collection'>
            <div className='collection-row collection-row-margin'>
                <div className='collection-row-item'>
                    <img src={wcmImage} className="collection-row-img-first" alt=''></img>
                </div>
                <div className='collection-row-item collection-bg-white'>
                    <div className='collection-row-item-content'>
                        <div className='row-item-content-title'>¡Obtén tu water point card y disfruta de los beneficios!</div>
                        <div className='row-item-content-list-item'>
                            <img src={icDot} alt='' className=''></img>
                            <div className='row-item-content-list-item-desc'> Recarga tu water Point card y <b> obtén 1 llenado gratis. </b></div>
                        </div>
                        <div className='row-item-content-list-item'>
                            <img src={icDot} alt='' className=''></img>
                            <div className='row-item-content-list-item-desc'> Después de 10 recargas la <b>11</b> va por nuestra cuenta</div>
                        </div>
                        <div className='row-item-content-list-item'>
                            <img src={icDot} alt='' className=''></img>
                            <div className='row-item-content-list-item-desc'> <b>Por cada recarga</b> puedes obtener un <b>boleto para los multiples regalos</b> que Water World tiene para ti.  </div>
                        </div>
                        <div className='row-item-content-list-item'>
                            <img src={icDot} alt='' className=''></img>
                            <div className='row-item-content-list-item-desc'> Utiliza tu tarjeta en cualquiera de nuestras plantas. </div>
                        </div>
                        <button onClick={() => handleWhatsapp("Hola, me gustaría hacer un pedido.")} className='collection-row-item-button'>¡Quiero comprar ahora!</button>
                    </div>
                </div>
            </div>
            <div className='collection-row collection-row-reverse'>
                <div className='collection-row-item collection-bg'>
                <div className='collection-row-item-content'>
                        <div className='row-item-content-title'>Suministro de agua purificada</div>
                        <div className='row-item-content-list-item'>
                            <div className='row-item-content-list-item-desc'>Te llevamos agua purificada directamente a tu hogar, negocio o proyecto en construcción, garantizando comodidad y calidad en cada entrega </div>
                        </div>
                        
                        <button onClick={() => handleWhatsapp("Hola, me gustaría hacer un pedido.")} className='collection-row-item-button'>¡Quiero comprar ahora!</button>
                    </div>
                </div>
                <div className='collection-row-item'>
                    <img src={collectionGallons} className="collection-row-img-second" alt=''/>
                </div>
            </div>
        </div>
        <div className='container-collage'>
            <div className='container-collage-head'> ¿A QUÉ INDUSTRIAS LLEVAMOS AGUA PURIFICADA? </div>
            <div className='container-collage-desc'> Descubre cómo nuestro servicio de distribución de agua purificada puede beneficiar a tu sector </div>
            <div className='container-collage-desc'> <b>¡Ordena tu agua puricada hoy mismo!</b> </div>
            <div className='div-line'/>
            <div className='collage-primary'>
                <img src={collageprim} alt='' />
                <div className='overlay-section-one'>
                    <div className='overlay-conteiner' >
                        <b>Calidad</b> <br/><br/>
                        Nuestra agua purificada garantiza la mejor calidad para tu salud y bienestar
                    </div>
                    <div className='overlay-dot' />
                </div>
                <div className='overlay-section-two'>
                    <div className='overlay-conteiner' >
                        <b>Limpieza</b> <br/><br/>
                        Disfruta de agua 100% limpia, libre de impurezas, perfecta para tu hogar y negocio 
                    </div>
                    <div className='overlay-dot' />
                </div>
                <div className='overlay-section-three'>
                    <div className='overlay-conteiner' >
                        <b>Frescura</b> <br/><br/>
                        Mantén la frescura en cada sorbo con nuestra agua purificada, ideal para los dias soleados de Los Cabos.
                    </div>
                    <div className='overlay-dot' />
                </div>
            </div>
            <div className='collage-secondary'>
                <img src={collageDos} alt='' />
                <img src={collageTres} alt='' />
                <img src={collageCuatro} alt='' />
            </div>
            <div className='collage-description'>
                <div className='collage-description-item'>Construcción</div>
                <div className='collage-description-item'>Hoteles</div>
                <div className='collage-description-item'>Restaurantes</div>
            </div>
        </div>
        <div className='container-our-clients'>
            <div className='container-collage-head'>CLIENTES QUE CONFIAN EN NOSOTROS</div>
            <div className='ourclients-list'>
                <SliderClients data={[ "client_1.svg", "client_2.svg", "client_3.svg", "client_4.svg", "client_5.svg", "client_6.svg", "client_7.svg", "client_8.svg", "client_9.svg"]}/>
            </div>
        </div>
        <div ref={divRef} className='container-workonme'>
            <div className='container-collage-head'>¿Quieres trabajar con nosotros?</div>
            <div className='container-collage-subhead'>¡Estamos contratando!</div>

            <div className='conteiner-form-map'>
                
                <div className='conteiner-form-workme'>
                    <form className='form-contact' ref={form} onSubmit={sendEmail}>
                    <div className='form-workme-title'> Escribenos, respondemos rápido</div>
                    <InputComponent onChange={(e) => setNameValue(e.target.value)}  value={nameField} placeholder="Ingresa tu nombre" label="Nombre" nameInput="user_name" required={true}/>
                    <InputComponent onChange={(e) => setEmailValue(e.target.value)} value={emailField} placeholder="Ingresa tu email" label="Email" nameInput="user_email" required={true}/>
                    <InputComponent onChange={(e) => setPhoneValue(e.target.value)} value={phoneField} placeholder="Ingresa tu teléfono" label="Teléfono"  nameInput="user_phone" required={true}/>
                    <InputComponent onChange={(e) => setMessageValue(e.target.value)} value={messageField} placeholder="Escribe tu comentario" label="Dejanos tu mensaje, Respondemos rápido" type="textArea" nameInput="message" required={true}/>
                    <div className='checkbutton-conteiner'>
                        <button className='' onClick={(e) => {
                            e.preventDefault()
                            setIsSendButtonDisbaled(!(emailField !== "" && phoneField !== "" && nameField !== "" && !isCheck));
                            setIsCheck(!isCheck)
                        }}>
                        {
                            isCheck ?
                            <CheckboxIcon className="checkbutton-icon"/>
                        :
                            <SquareIcon className="checkbutton-icon"/>
                        }
                            Al enviar este formulario, aceptas nuestra politica de privacidad ya utorizas el uso de tus datos personales conforme a la normatiba aplicable 
                        </button>
                    </div>
                    <div className='button-conteiner'>
                        <button type="submit" className='' disabled={isSendButtonDisbaled}>¡Quiero que me contacten!</button>
                    </div>
                    
                    <div className='address-conteiner'>
                        Calzada colina de San Jose Maz20 Lte 03 Colinas Baja California, Los Cabos Bcs, C.P. 23427 México.
                    </div>
                    </form>
                </div>
                <div className='conteiner-map-workme'> 
                    <MapComponent/>
                </div>
            </div>

        </div>
        <div className='container-upbutton'>
            <button onClick={gotoTop}> 
             <ArrowUpIcon className='container-upbutton-icon'/>
            </button>
            INICIO
        </div>
        <div className='container-banner'>
            <div className='div-line-black'/>
            <div className='banner-body'>
                <div className='banner-body-left'>
                    <div className='banner-body-title'>Únete a nuestra familia Water World</div>
                    <div className='banner-body-sep'></div>
                    <div className='banner-body-description'>Siempre actualizado de promociones y descuentos en nuestra tienda</div>
                </div>
                <div className='banner-body-right'>
                    <div className='banner-subscribe-conteiner'>
                        <input></input>
                        <button>Suscribete</button>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-footer'>
            <img src={bgFooter} className='dashboard-header-conteiner-img-f' alt=''/>
            <div className='container-footer-image'>
                <img src={logoWhite} alt=''/>
            </div>
            <div className='container-footer-text-one'>Water World es una empresa comprometida con la pureza y calidad del agua.</div>
            <div className='container-footer-text-two'>Con más de 23 años de experiencia, 7 sucursales  y tecnología de punta que nos respaldan,  para ofrecer soluciones eficientes y sostenibles para purificar agua y mejorar la calidad de vida en los Cabos.</div>
            <div className='container-footer-text-three dowm-text'>TODOS LOS DERECHOS RESERVADOS</div>
            <div className='conteiner-footer-final'>
                <div className='final-item-left'>
                    <div className='container-buttons-footer'>
                        <button onClick={() => {
                            const link = document.createElement('a');
                            link.href = `${process.env.PUBLIC_URL}/Aviso-de-Privacidad-Water-World.pdf`;
                            link.download = 'Aviso-de-Privacidad-Water-World.pdf'; // Nombre del archivo
                            link.click();
                        }}>
                        Política de privacidad
                        </button>
                    </div>
                </div>
                <div className='final-item-rigth'>
                    <div className='container-buttons-socialmedia-footer'>
                        <button onClick={handleFacebook}> <img src={iconFacebook} alt=''/> </button>
                    </div>
                    <div className='socialmedia-description'>SIGUENOS EN NUESTRAS REDES SOCIALES</div>
                </div>
                
            </div>
        </div>
        <img src={imgMarcaDeAgua} alt='' className='marcauno'></img>
        <img src={imgMarcaDeAgua} alt='' className='marcados'></img>
        <img src={imgMarcaDeAgua} alt='' className='marcatres'></img>
        <button className='buttonWhatsApp' onClick={() => handleWhatsapp("Hola, estoy interesado en más información.")}>
        <Lottie   options={defaultOptions}  height={80}  width={80} />
        </button>
        
    </div>
  );
};

export default Home;